(function ($) {
    var datatableFilterForm = {
          filterForm: 'form[data-provider="datatables"]',
          bindTriggers: function () {
              $('[data-filter-type]').change(function() {
                  datatableFilterForm.applyFilters();
              }).on('apply.daterangepicker', function(ev, picker) {
                  $(this).val(picker.startDate.format('YYYY-MM-DD') + ' , ' + picker.endDate.format('YYYY-MM-DD'));
                  datatableFilterForm.applyFilters();
              }).on('cancel.daterangepicker', function(ev, picker) {
                  $(this).val('');
                  datatableFilterForm.applyFilters();
              });
          },
          applyFilters: function () {
              fields = $('[data-filter-type]', datatableFilterForm.filterForm).serializeArray();
              query = '';
              $.each(fields, function () {
                  var fieldData = $('[name='+ this.name +']', datatableFilterForm.filterForm).data();
                  if(typeof fieldData != 'undefined' && this.value != '') {
                      if (fieldData.filterType == 'between') {
                          query += fieldData.filterColumn + '|' + fieldData.filterType + '|[' + this.value.replace(' , ', ',') + '];';
                      } else {
                          query += fieldData.filterColumn + '|' + fieldData.filterType + '|' + this.value + ';';
                      }
                  }
              });

              url = $(datatableFilterForm.filterForm).attr('action');
              if (query) {
                  query = '?filters=' + query;
              }

              window.location.href = url + query;
          },
          init: function () {
              if ($(datatableFilterForm.filterForm).length) {
                  datatableFilterForm.bindTriggers();
                  $(datatableFilterForm.filterForm).submit(function (event) {
                      datatableFilterForm.applyFilters();
                      event.preventDefault();
                  });
              }
          }
    }
    $(document).ready(datatableFilterForm.init());
    window.datatableFilterForm = datatableFilterForm;
}(jQuery));
