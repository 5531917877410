(function ($) {
    var tableFilterForm = {
          filterForm: 'form[data-provider="table"],form.auto-submit',
          bindTriggers: function () {
              $(':input').change(function() {
                  tableFilterForm.applyFilters();
              }).on('apply.daterangepicker', function(ev, picker) {
                  $(this).val(picker.startDate.format('YYYY-MM-DD') + ' , ' + picker.endDate.format('YYYY-MM-DD'));
                  tableFilterForm.applyFilters();
              }).on('cancel.daterangepicker', function(ev, picker) {
                  $(this).val('');
                  tableFilterForm.applyFilters();
              });
          },
          applyFilters: function () {
              fields = $(tableFilterForm.filterForm).serializeArray();
              query = '';
              $.each(fields, function () {
                  var fieldData = $('[name='+ this.name +']:not([data-ignore])', tableFilterForm.filterForm).data();
                  if(this.value != ''){
                    if(typeof fieldData != 'undefined') {
                      query += fieldData.filterColumn + '=' + this.value + '&';
                    } else {
                      query += this.name + '=' + this.value + '&';
                    }
                  }
              });

              url = $(tableFilterForm.filterForm).attr('action');
              if (query) {
                  query = '?' + query;
              }

              window.location.href = url + query.slice(0,-1);
          },
          init: function () {
              if ($(tableFilterForm.filterForm).length) {
                  tableFilterForm.bindTriggers();
                  $(tableFilterForm.filterForm).submit(function (event) {
                      event.preventDefault();
                      tableFilterForm.applyFilters();
                  });
              }
          }
    }
    $(document).ready(tableFilterForm.init());
    window.tableFilterForm = tableFilterForm;
}(jQuery));
