// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

$(function(){
  'use strict';
  jQuery.extend(jQuery.expr[':'], {
    attrStartsWith: function (el, _, b) {
        for (var i = 0, atts = el.attributes, n = atts.length; i < n; i++) {
            if(atts[i].nodeName.toLowerCase().indexOf(b[3].toLowerCase()) === 0) {
                return true;
            }
        }

        return false;
    }
});

  var options = {
    forms: 'form',
    cacheLength: 4,
    anchors: 'a:not([href*="#"], [data-remote], [data-provider], [data-toggle])',
    blacklist: '[href*="#"], form, .dataTableBuilder_length, [data-remote], [data-provider], [data-toogle], .no-smoothState',
    onStart: {
      duration: 0, // Duration of our animation
      render: function ($container) {
        // Add your CSS animation reversing class
        // $('.animated', $container).addClass('zoomOut');
        // Restart your animation
        smoothState.restartCSSAnimations();
      }
    },
    onReady: {
      duration: 0,
      render: function ($container, $newContent) {
        // Remove your CSS animation reversing class
        // $('.animated', $container).removeClass('zoomOut');
        // Inject the new content
        $container.html($newContent);
      }
    },
    onAfter: function($container, $newContent) {
        $.AdminLTE.controlSidebar.activate();
        pluginsWrapper.init();
        $(window).trigger('resize');
        $(document).ready();
        $(window).trigger('load');
    }
  }
  //,
  // smoothState = $('#smoothstate-wrapper').smoothState(options).data('smoothState');
});

(function ($) {
var RESTFullizer  = {
        init: function(){
            /*
             Allows you to add data-method="METHOD to links to automatically inject a form with the method on click
             Example: <a href="{{route('customers.destroy', $customer->id)}}" data-method="delete" data-label="delete" name="delete_item">Delete</a>
             Injects a form with that's fired on click of the link with a DELETE request.
             Good because you don't have to dirty your HTML with delete forms everywhere.
             */
             $('[data-method]:not(:has(form))').append(function(){
                return "\n"+
                "<form action='"+$(this).attr('href')+"' method='POST' data-label='"+$(this).data('label')+"'' name='"+$(this).data('method')+"_item' data-type='restful' style='display:none'>\n"+
                "   <input type='hidden' name='_method' value='"+$(this).attr('data-method')+"'>\n"+
                "   <input type='hidden' name='_token' value='"+$('meta[name="_token"]').attr('content')+"'>\n"+
                "</form>\n"
            })
             .removeAttr('href')
             .removeAttr('disabled')
             .removeClass('disabled')
             .attr('style','cursor:pointer;')
             .attr('onclick','$(this).find("form").submit();');

            /*
             Generic are you sure dialog
             */
             $('form[data-type=restful]').unbind('submit').bind('submit',function() {
                var label = $(this).data('label'),
                    parent = $(this).parent('a');
                    if(confirm("Are you sure you want to "+ label +" this item?")) {
                        $('i.fa', parent).removeClass().addClass('fa fa-spinner fa-pulse fa-fw');
                        $(parent).addClass('disabled');
                        return true;
                    } else {
                        return false;
                    }
            });
         }
    };
    window.RESTFullizer = RESTFullizer;
    var CustomSelect = {
        select2Focus: function () {
            $(this).closest('.select2').prev('select').select2('open');
        },
        init: function() {
            $('.select2:visible').select2({
                theme: "bootstrap"
            });
            $('select.select2').next('.select2').find('.select2-selection').one('focus', CustomSelect.select2Focus).on('blur', function () {
                $(this).one('focus', CustomSelect.select2Focus)
            })
        }
    };
    window.CustomSelect = CustomSelect;
    var InputMasks = {
        applyMasks: function(){
            $('input#cpf').inputmask("999.999.999-99");
            $('input#cpf').inputmask("999.999.999-99");
            $('input#zipcode').inputmask("99999-999");
            $('input#dob').inputmask("d/m/y",{ "placeholder": "dd/mm/aaaa" });
            $('input#phone_number,input#mobile_number,input#emergency_phone_number,input#emergency_mobile_number').inputmask("(99) 99999999[9]");
            $(":input").inputmask();
        }
    };
    var showTabs = {
        showTab: function(table) {
            activeTab = $('[href^=' + table + ']');
            activeTab && activeTab.tab('show');
        },
        fromHash: function(){
            var hash = location.hash;
            if(hash){
                hashPieces = hash.split('?'),
                table = hashPieces[0];
                showTabs.showTab(table);
            }
        },
        init: function() {
            showTabs.fromHash();

            $("a.display-tab").click(function(e) {
                showTabs.showTab($(this).attr('href'));
                e.preventDefault();
            });
        }
    };
    var dateRangePicker = {
        init: function () {
            if($('input[data-provider="daterangepicker"]').length && $.fn.daterangepicker){
                $('input[data-provider="daterangepicker"]').daterangepicker({
                    locale: {
                        format: 'YYYY-MM-DD'
                    },
                    autoUpdateInput: false,
                    ranges: {
                       'Today': [moment(), moment()],
                       'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                       'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                       'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                       'This Month': [moment().startOf('month'), moment().endOf('month')],
                       'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    }
                }).on('apply.daterangepicker', function(ev, picker) {
                    $(this).val(picker.startDate.format('YYYY-MM-DD') + ' , ' + picker.endDate.format('YYYY-MM-DD'));
                }).on('cancel.daterangepicker', function(ev, picker) {
                    $(this).val('');
                });
            }
        }
    };
    window.dateRangePicker = dateRangePicker;
    var dateRangePickerTravelling = {
        init: function () {
            if($('input[data-provider="daterangepickertravelling"]').length && $.fn.daterangepicker){
                $('input[data-provider="daterangepickertravelling"]').daterangepicker({
                    locale: {
                        format: 'YYYY-MM-DD'
                    },
                    autoUpdateInput: false,
                    ranges: {
                       'This Week': [moment().startOf('week'), moment().endOf('week').add(1, 'days')],
                       'Next 2 Weeks': [moment().startOf('week'), moment().add(1, 'weeks').endOf('week').add(1, 'days')],
                       'This Month': [moment().startOf('month'), moment().endOf('month').add(1, 'days')],
                    }
                }).on('apply.daterangepicker', function(ev, picker) {
                    $(this).val(picker.startDate.format('YYYY-MM-DD') + ' , ' + picker.endDate.format('YYYY-MM-DD'));
                }).on('cancel.daterangepicker', function(ev, picker) {
                    $(this).val('');
                });
            }
        }
    };
    var clockpicker = {
        init: function () {
            if($('input[data-provider="clockpicker"]').length && $.fn.clockpicker){
                $('input[data-provider="clockpicker"]').clockpicker();
            }
        }
    };
    var checkboxSwitch = {
        init: function () {
            $('.switch').bootstrapSwitch();
        }
    };
    window.checkboxSwitch = checkboxSwitch;
    var x_editable = {
        init: function () {
            $.fn.combodate.defaults = {
                minYear: 2016,
                maxYear: (new Date()).getFullYear() + 10,
                yearDescending: false,
            };
            if($('a[data-provider="x_editable"]').length && $.fn.editable){
                // Configure token parameter
                $.fn.editable.defaults.params = function (params) {
                    params._token = $("meta[name=_token]").attr("content");
                    return params;
                };
                // Init x-editable
                $('a[data-provider="x_editable"]').editable({
                    source: $(this).data('source'),
                    ajaxOptions: {
                        type: 'put',
                        dataType: 'json'
                    },
                    success: function (response, newValue) {
                        if (response.reload == true) {
                            location.reload();
                        } else {
                            return {newValue: newValue};
                        }
                    }
                })
                // If you want append an icon to toggle disabled, uncooment this lines
                // .after('<a href="#" class="toggle-xeditable btn btn-xs"><i class="fa fa-pencil"></i></a>');
                // $('a.toggle-xeditable').click(function(e){
                //     e.preventDefault();
                //     this.blur();
                //     $(this).prev().editable('toggleDisabled');
                // });
            }
        }
    };
    window.x_editable = x_editable;
    var formSubmits = {
        parsley_options: {
            excluded: "input:not(:visible),select:not(:visible)",
            errorClass: 'has-error',
            successClass: 'has-success',
            classHandler: function(ParsleyField) {
                return ParsleyField.$element.parents('.form-group');
            },
            errorsContainer: function(ParsleyField) {
                return ParsleyField.$element.parents('.form-group');
            },
            errorsWrapper: '<span class="help-block">',
            errorTemplate: '<div></div>'
        },
        init: function() {
            // When a field has an error
            window.Parsley.on('field:error', function(fieldInstance){
               if (typeof(toastr) !== "undefined") {

                   // Get the error messages
                   var messages = fieldInstance.getErrorsMessages();

                   // Get the field's name
                   var fieldName = fieldInstance.$element.attr('name');

                   // Loop through all the messages
                   for (var i in messages) {
                       // Create a message for each error
                       var $m = '<p>' + fieldName + ': ' + messages[i] + '<p>';
                       toastr.error($m);
                   }
               }
            });
            $("form:visible").each(function() {
                $(this).parsley().destroy();
                $(this).parsley(formSubmits.parsley_options);
            }   ).submit(function( event ) {
                var parsleyInstance = $(this).parsley();
                // if this form is valid
                if (parsleyInstance.isValid()) {
                    $(':submit', this).replaceWith(function(){
                        var classes = $(this).attr('class'),
                            width = $(this).outerWidth();
                        return $("<buttom />", {
                            html: "<i class='fa fa-spinner fa-pulse fa-fw'></i>",
                            class: classes + ' disabled',
                            disabled: 'disabled',
                            style: 'width:' + width + 'px'
                        });
                    });

                    return true;
                }

                // prevent default so the form doesn't submit. We can return true and
                // the form will be submited or proceed with a ajax request.
                event.preventDefault();
            });
        }
    };
    window.formSubmits = formSubmits;
    var bootstrapJsPlugins = {
        init: function() {
            /*
             Bind all bootstrap tooltips
             */
            $("[data-toggle=\"tooltip\"]").tooltip();
            $("[data-toggle=\"popover\"]").popover();
            //This closes the popover when its clicked away from
            $('body').on('click', function (e) {
                $('[data-toggle="popover"]').each(function () {
                    if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                        $(this).popover('hide');
                    }
                });
            });

            $('body').on('shown.bs.collapse', function () {
                RESTFullizer.init();
                // ALWATS INIT SELECT2 BEFOR PARSLEY
                CustomSelect.init();
                formSubmits.init();
                InputMasks.applyMasks();
                x_editable.init();
                dateRangePicker.init();
                dateRangePickerTravelling.init();
                clockpicker.init();
            });
            $('body').on('shown.bs.modal', function () {
                RESTFullizer.init();
                // ALWATS INIT SELECT2 BEFOR PARSLEY
                CustomSelect.init();
                formSubmits.init();
                InputMasks.applyMasks();
                x_editable.init();
                dateRangePicker.init();
                dateRangePickerTravelling.init();
                clockpicker.init();
            });
            $('body').on('shown.bs.tab', function () {
                RESTFullizer.init();
                // ALWATS INIT SELECT2 BEFOR PARSLEY
                CustomSelect.init();
                formSubmits.init();
                InputMasks.applyMasks();
                x_editable.init();
                dateRangePicker.init();
                dateRangePickerTravelling.init();
                clockpicker.init();
            });
        }
    };
    var zipCodeAjax = {
        init: function() {
            //Check if there is any field name address_zip_code on the page
            if($('[name=address_zip_code]').length){
                var form = $('[name=address_zip_code]').parents('form');
                $('[name=address_zip_code]',form).consultarCep({
                    evento:        'blur',
                    focarAposPara: '[name=address_number]',
                    mensagem:      '<i class="fa fa-spin fa-spinner"></i>',
                    classMensagem: 'label label-default',
                    sucesso: function(endereco) {
                        if($('[name=address]',form).length) {
                            $('[name=address]',form).val(endereco.logradouro);
                        }
                        if($('[name=address_neighborhood]',form).length){
                            $('[name=address_neighborhood]',form).val(endereco.bairro);
                        }
                        if($('[name=address_city]',form).length){
                            $('[name=address_city]',form).val(endereco.localidade);
                        }
                        if($('[name=address_state]',form).length){
                            $('[name=address_state]',form).val(endereco.uf);
                            $('[name=address_state]',form).change();
                        }
                    }
                });
            }
            if($('[data-ajax-address=zipcode]').length){
                var form = $('[data-ajax-address=zipcode]').parents('form');
                $('[data-ajax-address=zipcode]',form).consultarCep({
                    evento:        'click',
                    btnConsultar:  '#consultar-cep',
                    focarAposPara: '[data-ajax-address=number]',
                    mensagem:      '<i class="fa fa-spin fa-spinner"></i>',
                    classMensagem: 'label label-default',
                    sucesso: function(endereco) {
                        if($('[data-ajax-address=address]',form).length) {
                            $('[data-ajax-address=address]',form).val(endereco.logradouro);
                        }
                        if($('[data-ajax-address=district]',form).length) {
                            $('[data-ajax-address=district]',form).val(endereco.bairro);
                        }
                        if($('[data-ajax-address=city]',form).length) {
                            $('[data-ajax-address=city]',form).val(endereco.localidade);
                        }
                        if($('[data-ajax-address=state]',form).length) {
                            $('[data-ajax-address=state]',form).val(endereco.uf);
                        }
                        if($('[data-ajax-address=city_code]',form).length) {
                            $('[data-ajax-address=city_code]',form).val(endereco.cidade_info.codigo_ibge);
                        }
                    }
                });
            }
        }
    };
    var dataTablesCallback = {
        init: function() {
            if($('table.dataTable').length && window.location.pathname.indexOf('trip') === -1){
                var dataTableSettings = $('table.dataTable').dataTable().fnSettings();
                dataTableSettings.aoDrawCallback.push( {
                    "fn": function () {
                        RESTFullizer.init();
                        formSubmits.init();
                        x_editable.init();
                    },
                });
            }
        }
    }
    window.dataTablesCallback = dataTablesCallback;

    var ajaxFormOverlay = {
        init: function() {
            formSubmits.init();
            $('body').on('hidden.bs.modal', '#AjaxFormOverlay', function () {
              $(this).removeData('bs.modal');
              $('.modal-content', '#AjaxFormOverlay').html('<div class="box-body text-center"><i class="center-block fa fa-5x fa-circle-o-notch fa-spin"></i></div>');
            });

            $('#AjaxFormOverlay').on('loaded.bs.modal', function (e) {
                RESTFullizer.init();
                // ALWATS INIT SELECT2 BEFOR PARSLEY
                CustomSelect.init();
                formSubmits.init();
                InputMasks.applyMasks();
                x_editable.init();
                dateRangePicker.init();
                dateRangePickerTravelling.init();
                clockpicker.init();
                $('form','#AjaxFormOverlay').submit(function(event) {
                  // stop the form from submitting the normal way and refreshing the page
                  event.preventDefault();
                  // get the form data
                  // there are many ways to get this data using jQuery (you can use the class or id also)
                  var formData = $(this).serialize();

                  // process the form
                  $.ajax({
                      type        : $(this).attr('method'),
                      url         : $(this).attr('action'), // the url where we want to POST
                      data        : formData, // our data object
                      dataType    : 'json', // what type of data do we expect back from the server
                      encode      : true
                  })
                  // using the done promise callback
                  .done(function(data) {
                    $('#AjaxFormOverlay').modal('hide');
                    toastr.success(data.message);
                  }).error(function(data) {
                    toastr.error(data.error);
                  });
                });
            })
        }
    }
    window.ajaxFormOverlay = ajaxFormOverlay;

    var pluginsWrapper = {
        init: function() {
            RESTFullizer.init();
            // ALWATS INIT SELECT2 BEFOR PARSLEY
            CustomSelect.init();
            formSubmits.init();
            InputMasks.applyMasks();
            x_editable.init();
            dateRangePicker.init();
            dateRangePickerTravelling.init();
            clockpicker.init();
            datatableFilterForm.init();
            tableFilterForm.init();
            checkboxSwitch.init();
            bootstrapJsPlugins.init();
            zipCodeAjax.init();
        }
    };
    window.pluginsWrapper = pluginsWrapper;

    // Place any jQuery/helper plugins in here.
    $( document ).ready(function() {
        console.log('documentReady')
        pluginsWrapper.init();
        showTabs.init();
        ajaxFormOverlay.init();
        dataTablesCallback.init();
    });
}(jQuery));